import { Container, Row, Col } from "react-bootstrap";
import { GlobeComponent } from "../../../features/GlobeComponent/GlobeComponent";
import { Navbar } from "../../Navbar/Navbar";
import { Link } from "react-router-dom";

import "./Header.css";



export const Header = () => {



  return (
    <Container fluid>
      <Row className="header-navbar-row">
        <Col>
          <Navbar/>
        </Col>
      </Row>
      <Row className="canvas-row">
        <Col className="canvas-col">
          <GlobeComponent />
          <Container >
            <Row >
              <Col >

              <div className="titleArea">
                <p style={{fontFamily: "Tango"}}>Find a leading</p>
        
                <h1><span className="textGradient">WEb3</span>&nbsp;
                <span style={{fontFamily: "Tango"}}>Lawyer,</span></h1>
         
                <p style={{fontFamily: "Tango-Light"}}>anywhere.</p>
              </div>  

                <div id="mobileParagraph">
                <br></br>
                <h5  style={{
                  borderTop: "1px solid #fff",
                  paddingTop: "15px"
                }}>Blockchain Lawyers Group connects a global network of legal experts in blockchain and crypto asset-related matters increasing the ease of access to competent and qualified legal advice.</h5>
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>

              <Row style={{textAlign: "center", backgroundColor: "rgba(0,0,0,0.5)", marginTop: "50px", marginBottom: "50px"}}>
                <Row >
                  <Col>
                    <h2 style={{padding: "10px 24px 10px 24px", fontFamily: "Rajdhani-Light"}}>Take advantage of the directory to talk to some of the world's leading Web3 legal experts</h2>
                  </Col>
                </Row>
                <Row style={{textAlign: "center"}}>
                  <Col>
                  <Link to="/directory"> 
                  <button className="btnHover" style={{fontSize: "1.5em", border: "1px solid #fff", padding: "5px 15px 5px 15px", marginBottom: "24px", borderRadius: "15px"}}>Visit Directory</button>
                  </Link>
                  </Col>
                </Row>
              </Row>
    </Container>
  );
}