import { useAppSelector } from '../../app/hooks';
import './Loader.css'
import { selectLoading } from './loaderSlice';

export const Loader = () => {
  const isLoading = useAppSelector(selectLoading)

  return (
    <>
      {isLoading && 
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      }
    </>
  );
}