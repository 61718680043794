import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { BsTwitter, BsLinkedin, BsMedium } from "react-icons/bs";
import { FaDiscord } from "react-icons/fa";

import { useAppDispatch } from "../../../app/hooks";
import { toggle } from "../../../features/applicationForm/applicationFormSlice";

import "./Footer.css";
import Chat from "../../../images/chat.svg";

export const Footer = () => {
  const dispatch = useAppDispatch();

  const handleClick = () => dispatch(toggle({}));
  
  return (
    <footer>
      <Container  style={{width: "100%"}}>

        <Row >
          <Col>
            <button className=" apply-btn" onClick={handleClick}>Apply</button>
          </Col>
        </Row>

 {/*      <Row>
          <Col align="center" style={{fontFamily: "Rajdhani", marginBottom: "-10px"}}>
            <h3>Partners</h3>
          </Col>
        </Row>
*/}    


        <Row style={{marginTop: "45px", marginBottom: "24px"}}>
          <Col className="socials-col" >
            <a href='https://twitter.com/BlockchainLG' target='_blank' rel='noreferrer' className="footer-socials"><BsTwitter /></a>
            <a href='https://www.linkedin.com/company/blockchain-lawyers-group/' target='_blank' rel='noreferrer' className="footer-socials"><BsLinkedin /></a>
            <a href='https://medium.com/blockchain-lawyers-group' target='_blank' rel='noreferrer' className="footer-socials"><BsMedium /></a>
            <a href='https://discord.gg/A2aAafhhWT' target='_blank' rel='noreferrer' className="footer-socials"><FaDiscord /></a>
          </Col>
        </Row>

        
        <div style={{width: "100%", textAlign: "center"}}>
            <span style={{ bottom: "0"}} className="textGradient copy">Copyright © 2022 Blockchain Lawyers Group All Rights Reserved.</span>
        </div>

        <a href="mailto:info@blockchainlawyers.group" target="_blank" rel="noreferrer" style={{paddingLeft: "10px"}}>
            <img className="chat" src={Chat} alt=""></img>
        </a>

      </Container>
    </footer>
  );
}