import { createSlice } from "@reduxjs/toolkit";
import { RootState } from '../../app/store';

export interface LoginState {
  isLoggedIn: boolean;
}

const initialState: LoginState = {
  isLoggedIn: false,
};

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setLoggedIn: (state) => {
      state.isLoggedIn = !state.isLoggedIn;
    },
  },
});

export const { setLoggedIn } = loginSlice.actions;

export const selectLogin = (state: RootState) => state.login.isLoggedIn;

export default loginSlice.reducer;
