import { createSlice } from "@reduxjs/toolkit";
import { RootState } from '../../app/store';

export interface LoaderState {
  show: boolean;
}

const initialState: LoaderState = {
  show: false,
};

export const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    setLoading: (state) => {
      state.show = !state.show;
    },
  },
});

export const { setLoading } = loaderSlice.actions;

export const selectLoading = (state: RootState) => state.loader.show;

export default loaderSlice.reducer;
