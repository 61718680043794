import { Footer } from "./Footer/Footer";
import { Header } from "./Header/Header";
import { ProjectOverview } from "./Project Overview/ProjectOverview";
import "./Home.css";



export const Home = () => {
  return (
    <>
      <script id="CookieDeclaration" src="https://consent.cookiebot.com/7fac0229-d1c1-420e-b070-cc5554f9448b/cd.js" type="text/javascript" async></script>
      <Header />
      <ProjectOverview/>

      <Footer />
    </>
  );
}