import { Button, Form, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectData, selectOpen, toggle } from "./applicationFormSlice";
import { FilterComponent } from "../../components/Directory/TableDirectory/TableFilter/FilterComponent/FilterComponent";

import "./ApplicationForm.css";
import LawyerDataService from "../../services/lawyer";
import { setLoading } from "../Loader/loaderSlice";

type FormData = {
  name: string,
  jurisdiction: string,
  bio: string,
  expertiseAreas: string[],
  email: string,
  linkedIn: string,
  twitter: string,
  facePhoto: string,
  walletAddress: string,
  experience: string
}

const FORM_INITIAL_STATE: FormData = {
  name: '',
  jurisdiction: '',
  bio: '',
  expertiseAreas: [],
  email: '',
  linkedIn: '',
  twitter: '',
  facePhoto: '',
  walletAddress: '',
  experience: ''
}

export const ApplicationForm = () => {
  const show = useAppSelector(selectOpen);
  const data = useAppSelector(selectData);
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState(FORM_INITIAL_STATE);
  const [changedPhoto, setChangedPhoto] = useState(false);

  useEffect(() => {
    setFormData(data?.email ? data as FormData : FORM_INITIAL_STATE)
  }, [data])

  const handleOpened = () => dispatch(toggle({}));
  
  const handleFileRead = async (event: any) => {
    setChangedPhoto(true);
    const file = event.target.files[0]
    const base64 = await convertBase64(file)
    setFormData({ ...formData, facePhoto: (base64 as string) })
  }

  const convertBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }

  const getJurisdiction = (jur: string) => {
    setFormData({...formData, jurisdiction: jur})
  }

  const successNotify = () => toast.success('Thank you for your application. We have duly received it and will be in touch via e-mail as soon as possible.', {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  const notify = () => toast.error('This email already exists!', {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  const handleSubmit = async (event: any) => {
    dispatch(setLoading())
    event.preventDefault();
    event.stopPropagation();
    setFormData({...formData, expertiseAreas: formData.expertiseAreas.map(x => x.trim())})
    if(!changedPhoto && data.email) {
      setFormData({...formData, facePhoto: data.facePhoto as string})
    }

    const exists = await LawyerDataService.lawyerExists({email: formData.email})
    if(!exists.data) {
      if (event.target.checkValidity() === true && formData.jurisdiction?.length) {
        await LawyerDataService.createStandbyLawyer(formData)
        dispatch(toggle({}))
        successNotify();
      } else {
        notify();
      }
    } else if(data.email) {
      if(data.email === formData.email) {
        if (event.target.checkValidity() === true && formData.jurisdiction?.length) {
          await LawyerDataService.deleteLawyer(formData.email)
          await LawyerDataService.createLawyer(formData)
          dispatch(toggle({}))
          successNotify();
        } else {
          notify();
        }
      }
    } else {
      notify();
    }
    dispatch(setLoading())
  };

  return (
    <Modal show={show} onHide={handleOpened} className="modal">
      <Modal.Header>
        <Modal.Title>Application Form</Modal.Title>
        <button onClick={handleOpened} type="button" className="btn-close btn-close-white" aria-label="Close"></button>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="formBasicName">
            <Form.Label>Full Name</Form.Label>
            <Form.Control type="text" placeholder="Enter name" 
              defaultValue={data.name ? data.name as string : undefined}
              onChange={e => setFormData({...formData, name: e.target.value})} required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicJurisdiction">
            <Form.Label>Jurisdiction</Form.Label>
            <FilterComponent 
              isFilter={false}
              className="form-filter"
              func={getJurisdiction}
              defaultVal={data.jurisdiction ? data.jurisdiction : ''}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicBio">
            <Form.Label>Biography</Form.Label>
            <Form.Control as="textarea" rows={5} required
              defaultValue={data.bio ? data.bio as string : undefined}
              onChange={e => {
                setFormData({...formData, bio: e.target.value})
                const strLength = e.target.value.split(' ').length;
                if(strLength > 150) {
                  const first150 = e.target.value.split(' ').slice(0, 150)
                  const str = first150.reduce((prev, curr) => {
                    return prev.concat(`${curr} `)
                  }, '')
                  setFormData({...formData, bio: str})
                }
              }}
              value={formData.bio}
            />
            <Form.Text className="text-muted">
              150 maximum words.
            </Form.Text>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicExpertise">
            <Form.Label>Areas of Expertise</Form.Label>
            <Form.Control type="text" placeholder="Enter areas"
              defaultValue={data.expertiseAreas ? data.expertiseAreas.join('; ') : undefined}
              onChange={e => {
                setFormData({
                  ...formData, 
                  expertiseAreas: e.target.value.split(';')
                })
                const commas = e.target.value.split(';');
                const nrCommas = commas.length;
                if(nrCommas > 4) {
                  setFormData({
                    ...formData, 
                    expertiseAreas: e.target.value.split(';').slice(0,4)
                  })
                }
              }}
              value={formData.expertiseAreas.join(';')}
            />
            <Form.Text className="text-muted">
              Please separate the areas with a ";" (max. 4).
            </Form.Text>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" placeholder="Enter email"
              defaultValue={data.email ? data.email as string : undefined}
              onChange={e => setFormData({...formData, email: e.target.value})} required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicLinkedIn">
            <Form.Label>LinkedIn</Form.Label>
            <Form.Control type="text" placeholder="Enter LinkedIn link"
              defaultValue={data.linkedIn ? data.linkedIn as string : undefined}
              onChange={e => setFormData({...formData, linkedIn: e.target.value})}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicTwitter">
            <Form.Label>Twitter</Form.Label>
            <Form.Control type="text" placeholder="Enter Twitter link"
              defaultValue={data.twitter ? data.twitter as string : undefined}
              onChange={e => setFormData({...formData, twitter: e.target.value})}
            />
          </Form.Group>
          {
            !data.email ? 
            <Form.Group className="mb-3" controlId="formBasicFace">
              <Form.Label>Face Photo</Form.Label>
              <Form.Control type="file" accept='.png,.jpg,.jpeg,.webp'
                onChange={e => handleFileRead(e)} required/>
            </Form.Group> :
            <Form.Group className="mb-3" controlId="formBasicFace">
              <Form.Label>Face Photo</Form.Label>
              <Form.Control type="file" accept='.png,.jpg,.jpeg,.webp'
                onChange={e => handleFileRead(e)}/>
            </Form.Group>
          }
          <Form.Group className="mb-3" controlId="formBasicWallet">
            <Form.Label>Wallet Address</Form.Label>
            <Form.Control type="text" placeholder="Enter wallet address"
              defaultValue={data.walletAddress ? data.walletAddress as string : undefined}
              onChange={e => setFormData({...formData, walletAddress: e.target.value})} required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicExperience">
            <Form.Label>Experience</Form.Label>
            <Form.Control type="text" placeholder="Enter your experience"
              defaultValue={data.experience ? data.experience as string : undefined}
              onChange={e => setFormData({...formData, experience: e.target.value})} required
            />
            <Form.Text className="text-muted">
              Relevant past experience advising Web3 companies and/or individuals.
            </Form.Text>
          </Form.Group>
          {
            !data.email ? 
            <Form.Group className="mb-3" controlId="formBasicCheck">
              <Form.Check
                required
                label="By checking the box below I hereby warrant and declare that all statements included in this application form are true, complete and accurate and that I am fully qualified to practice law and render legal advice under the jurisdiction I am applying to, without any limitations or restrictions."
                feedback="You must agree before submitting."
                feedbackType="invalid"
              />
            </Form.Group> :
            null
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleOpened}>
            Close
          </Button>
          <Button type="submit" variant="primary">
            Submit
          </Button>
        </Modal.Footer>
      </Form>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Modal>
  );
}