import React from 'react';
import { Routes, Route } from "react-router-dom";
import './App.css';
import  Articles  from './components/AllPosts';
import Singles from './components/OnePost';
import { Home } from './components/Home/Home';
import { ApplicationForm } from './features/applicationForm/ApplicationForm';
import { Directory } from './components/Directory/Directory';
import { AcceptUsers } from './components/AcceptUsers/AcceptUsers';
import { Loader } from './features/Loader/Loader';


function App() {
  return (
    <div className='app-container bg'>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/directory" element={<Directory />} />
        <Route path="/articles" element={<Articles />} />
        <Route path="/:slug" element={<Singles />} />
        <Route path="/acceptUsers" element={<AcceptUsers />} />
      </Routes>
      <ApplicationForm />
      <Loader />
    </div>
  );
}

export default App;
