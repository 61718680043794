import { useEffect, useState } from "react";
import Select from 'react-select';

import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { selectFilterKeyw, setFilterJur, setLawyersArr } from "../../../../../features/lawyers/lawyersSlice";
import { setLoading } from "../../../../../features/Loader/loaderSlice";
import LawyersDataService from "../../../../../services/lawyer";
import { GroupedOption, groupedOptions } from "./../jurisdictionData";

import "./FilterComponent.css";

type IFilterComponent = {
  className?: string,
  func?: (x: any) => any,
  isFilter: boolean,
  defaultVal?: string
}

export const FilterComponent = ({className, func, isFilter, defaultVal}: IFilterComponent) => {
  const [groupOptions, setGroupOptions] = useState(groupedOptions);
  const dispatch = useAppDispatch();
  const keyw = useAppSelector(selectFilterKeyw);

  useEffect(() => {
    dispatch(setFilterJur(''))
  }, [dispatch])

  useEffect(() => {
    const startFilter = async () => {
      dispatch(setLoading())
      await LawyersDataService.getAvailableJurisdictions()
      .then(res => {
        const dataArr = res.data.filter((x: string) => x !== null).reduce((prev: any, curr: string) => {
          prev.push({ value: curr, label: curr });
          return prev
        },[])
        setGroupOptions(dataArr)
      })
      .catch(e => {
        console.log(e)
      })
      dispatch(setLoading())
    } 

    if(isFilter) startFilter()
  }, [isFilter, dispatch])

  const handleChange = async (e: any) => {
    if(isFilter) {
      dispatch(setLoading())
      dispatch(setFilterJur(e.label.length ? e.label : ''))
      const newLawyers = await LawyersDataService.getAll(e.label.length ? e.label : '', keyw)
      if(newLawyers.data.length) dispatch(setLawyersArr(newLawyers.data))
      dispatch(setLoading())
    }
    if(e !== undefined && func) func(e.label)
  }

  return (
    <div className={className}>
      <Select<any, false, GroupedOption>
        options={groupOptions}
        placeholder="Jurisdiction"
        onChange={handleChange}
        defaultInputValue={defaultVal}
        theme={(theme) => ({
          ...theme,
          borderRadius: 4,
          colors: {
          ...theme.colors,
            text: 'rgba(54, 140, 255, 1)',
            primary25: 'rgba(54, 140, 255, 0.3)',
            primary50: 'rgba(54, 140, 255, 1)',
            primary: 'rgba(54, 140, 255, 1)',
          },
        })}
      />
    </div>
  );
}