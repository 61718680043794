import { 
  useReactTable, 
  getCoreRowModel, flexRender, ColumnDef, Row as row, getExpandedRowModel
} from "@tanstack/react-table";
import { Fragment, ReactNode, useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { BsLinkedin, BsTwitter, BsEnvelopeFill } from "react-icons/bs";
import { MdOutlineKeyboardArrowUp } from "react-icons/md";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { toggle } from "../../../../features/applicationForm/applicationFormSlice";
import { selectFilterJur, selectLawyers } from "../../../../features/lawyers/lawyersSlice";
import { setLoading } from "../../../../features/Loader/loaderSlice";
import { selectLogin } from "../../../../features/login/loginSlice";
import LawyerDataService from "../../../../services/lawyer";
import * as countriesArr from './../../../../utils/countriesAndFlags.json';
import "./TableComponent.css";

type TableEntry = {
  jurisdiction: string,
  name: string,
  bio?: string,
  expertiseAreas?: string[],
  email: string,
  linkedIn?: string,
  twitter?: string
  facePhoto: string,
  expander?: any
}

type TableProps<TData> = {
  data: TData[]
  columns: ColumnDef<TData>[]
  getRowCanExpand: (row: row<TData>) => boolean
}

function Table({
  data,
  columns,
  getRowCanExpand,
}: TableProps<TableEntry>): JSX.Element {
  const table = useReactTable<TableEntry>({
    data,
    columns,
    getRowCanExpand,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel()
  })

  return (
    <div>
      <div className="h-2" />
      <table>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header, idx) => {
                const width = idx === 0 ? '25%' : idx === 1 ? '40%' : 'auto';
                return (
                  <th 
                    {...{
                      key: header.id,
                      colSpan: header.colSpan,
                      style: {
                        width: width
                      }
                    }}
                  >
                    {header.isPlaceholder ? null : (
                      <div>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </div>
                    )}
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map(row => {
            return (
              <Fragment key={row.id}>
                <tr>
                  {/* first row is a normal row */}
                  {row.getVisibleCells().map((cell, idx) => {
                    const width = idx === 0 ? '25%' : idx === 1 ? '40%' : 'auto';
                    return (
                      <td 
                        {...{
                          key: cell.id,
                          style: {
                            width: width
                          }
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    )
                  })}
                </tr>
                {row.getIsExpanded() && (
                  <tr>
                    {/* 2nd row is a custom 1 cell row */}
                    <td colSpan={row.getVisibleCells().length}>
                      {<RenderSubComponent row={ row } />}
                    </td>
                  </tr>
                )}
              </Fragment>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

const RenderSubComponent = ({ row }: { row: row<TableEntry> }) => {
  const isLoggedIn = useAppSelector(selectLogin);
  const dispatch = useAppDispatch();

  const handleClick = () => dispatch(toggle(row.original));

  const handleDelete = async () => {
    if(window.confirm('Are you sure you want to remove this member from the database?')){
      dispatch(setLoading())
      await LawyerDataService.deleteLawyer(row.original.email)
      dispatch(setLoading())
    }
  }

  return (
    <Container>
      <Row>
        <Col sm={3}>
          <p>Areas of Expertise:</p>
          <ul className="expertise-areas-ul">
            {row.original.expertiseAreas?.map((e) => {
              return <li className="expertise-areas-li">{e}</li>
            })}
          </ul>
        </Col>
        <Col sm={5}>
          <p className="bio-p">{row.original.bio}</p>
        </Col>
        <Col sm={4} className="padding-0-imp image-in-table">
          <img className="image-cropper-img" src={row.original.facePhoto} alt={row.original.name}/>
          <Row>
            <Col className="links-in-table">
              {row.original.email && <a href={`mailto:${row.original.email}`} target="_blank" rel="noreferrer" className="table-socials"><BsEnvelopeFill /></a>}
              {row.original.linkedIn && <a href={row.original.linkedIn} target="_blank" rel="noreferrer" className="table-socials"><BsLinkedin /></a>}
              {row.original.twitter && <a href={row.original.twitter} target="_blank" rel="noreferrer" className="table-socials"><BsTwitter /></a>}
              {isLoggedIn && <Button onClick={handleClick}>Edit</Button>}
              {isLoggedIn && <Button variant="danger" onClick={handleDelete}>Delete</Button>}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

const columns: ColumnDef<TableEntry>[] = [
  {
    accessorKey: 'jurisdiction',
    header: () => {
      return (
        <Container>
          <Row>
            <Col>
              <p className="table-column-title">Jurisdiction</p>
            </Col>
          </Row>
        </Container>
      );
    },
    cell: (info) => {
      let country: string = '';
      if((info.getValue() as string).includes(',')){
        country = (info.getValue() as string).split(',')[0];
      } else {
        country = (info.getValue() as string);
      }
      const flag = countriesArr[country as keyof typeof countriesArr];
      return (
        <Container style={{margin: "1rem 0"}}>
          <Row>
            <Col>
              <img className="flag-img" src={flag} alt={info.getValue() as string}/>
              <p className="table-column-title">{info.getValue() as ReactNode}</p>
            </Col>
          </Row>
        </Container>
      );
    }
  },
  {
    accessorKey: 'name',
    header: () => {
      return (
        <Container>
          <Row>
            <Col>
              <p className="table-column-title">Name</p>
            </Col>
          </Row>
        </Container>
      );
    },
    cell: (info) => {
      return (
        <Container>
          <Row>
            <Col>
              {<h2 className="table-column-title">{info.getValue() as ReactNode}</h2>}
            </Col>
          </Row>
        </Container>
      );
    }
  },
  {
    id: 'expander',
    header: () => null,
    cell: ({ row }) => {
      return row.getCanExpand() ? (
        <Container style={{ textAlign: "center" }}>
          <button className="table-expand-button"
            {...{
              onClick: row.getToggleExpandedHandler()
            }}
          >
            <MdOutlineKeyboardArrowUp className={row.getIsExpanded() ? "active": ""} 
              onClick={(e) => {
                if(!e.currentTarget.classList.contains('active')){
                  const allTds = e.currentTarget.parentNode?.parentNode?.parentNode?.parentElement?.children;
                  for(let i = 0; i < allTds!.length; i++){
                    allTds![i].setAttribute('class', 'td-no-border')
                  }
                } else {
                  const allTds = e.currentTarget.parentNode?.parentNode?.parentNode?.parentElement?.children;
                  for(let i = 0; i < allTds!.length; i++){
                    allTds![i].removeAttribute('class')
                  }
                }
              }}
            />
          </button>
        </Container>
      ) : (
        ''
      )
    },
  }
]

export const TableComponent = () => {
  const lawyers = useAppSelector(selectLawyers)
  const jur = useAppSelector(selectFilterJur)
  const [data, setData] = useState<TableEntry[]>([]);

  useEffect(() => {
    if(jur.length){
      if(lawyers.length) setData([...lawyers])
      else setData([])
    } else{
      setData([])
    }
  }, [lawyers, jur])

  return (
    <Table
      data={data}
      columns={columns}
      getRowCanExpand={() => true}
    />
  )
}