import { initializeApp } from 'firebase/app'
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyBpQGoMt56Yi45FsirBBOSMQpQphnGMRO8",
  authDomain: "blg-users-db.firebaseapp.com",
  projectId: "blg-users-db",
  storageBucket: "blg-users-db.appspot.com",
  messagingSenderId: "907714282369",
  appId: "1:907714282369:web:0726b1406390c5e3b11e80"
};

// Initialize Firebase
initializeApp(firebaseConfig);
const provider = new GoogleAuthProvider();

provider.setCustomParameters({
  prompt: "select_account"
});

export const auth = getAuth();
export const signInWithGooglePopup = () => signInWithPopup(auth, provider);