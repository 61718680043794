import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { selectFilterJur, setFilterKeyw, setLawyersArr } from "../../../../features/lawyers/lawyersSlice";
import LawyersDataService from "../../../../services/lawyer";

import { FilterComponent } from "./FilterComponent/FilterComponent";

import "./TableFilter.css";

export const TableFilter = () => {
  const [inputVal, setInputVal] = useState('');
  const [displayMessage, setDisplayMessage] = useState('');
  const dispatch = useAppDispatch();
  const jur = useAppSelector(selectFilterJur);

  useEffect(() => {
    const timeOutId = setTimeout(() => setDisplayMessage(inputVal), 800);
    return () => clearTimeout(timeOutId);
  }, [inputVal]);

  useEffect(() => {
    const dispatchFilterKeyw = async () => {
      dispatch(setFilterKeyw(displayMessage))
    }
    dispatchFilterKeyw();
    const dispatchNewLawyer = async () => {
      const newLawyers = await LawyersDataService.getAll(jur, displayMessage)
      if(newLawyers.data.length) dispatch(setLawyersArr(newLawyers.data))
    }
    dispatchNewLawyer();
  }, [displayMessage, jur, dispatch]);

  const handleChange = async (e: any) => {
    setInputVal(e.target.value)
  }

  return (
    <Container className="filter-container">
      <Row>
        <Col className="filter-col">
          <FilterComponent isFilter/>
        </Col>
        <Col>
          <input style={{backgroundColor: "rgba(255, 255, 255, 0.1)", color: "#fff"}} value={inputVal} placeholder="Search for a Lawyer or Keyword" 
            className="filter-name-input" type="text" onChange={handleChange}
          />
        </Col>
      </Row>
    </Container>
  );
}