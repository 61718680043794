import Slider from "../../../features/Slider/Slider";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './ProjectOverview.css';

export const ProjectOverview = () => {
  return (


      <Slider/>


  );
}