import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

type TableEntry = {
  jurisdiction: string,
  name: string,
  bio?: string,
  expertiseAreas?: string[],
  email: string,
  linkedIn?: string,
  twitter?: string
  facePhoto: string,
  expander?: any
}

type IFilter = {
  jur: string,
  keyw: string
}

export interface LawyersState {
  lawyers: TableEntry[],
  filter: IFilter
}

const initialState: LawyersState = {
  lawyers: [],
  filter: {
    jur: '',
    keyw: ''
  }
};

export const lawyersSlice = createSlice({
  name: 'lawyers',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setLawyersArr: (state, action: PayloadAction<TableEntry[]>) => {
      state.lawyers = action.payload;
    },
    setFilterJur: (state, action: PayloadAction<string>) => {
      state.filter.jur = action.payload;
    },
    setFilterKeyw: (state, action: PayloadAction<string>) => {
      state.filter.keyw = action.payload;
    },
  },
});

export const { setLawyersArr, setFilterJur, setFilterKeyw } = lawyersSlice.actions;

export const selectLawyers = (state: RootState) => state.lawyers.lawyers;
export const selectFilterJur = (state: RootState) => state.lawyers.filter.jur;
export const selectFilterKeyw = (state: RootState) => state.lawyers.filter.keyw;

export default lawyersSlice.reducer;
