import http from "../http-common"

class LawyerDataService {
  async getAll(jurisdiction?: string, keyword?: string){
    const jur = jurisdiction ? `jurisdiction=${jurisdiction}` : ''
    const keyw = keyword ? `keyword=${keyword}` : ''
    let params = ''
    if(jurisdiction?.length && keyword?.length) params = `${jur}&${keyw}`
    else if(jurisdiction?.length && !keyword?.length) params = `${jur}`
    else if(!jurisdiction?.length && keyword?.length) params = `${keyw}`
    else return {data: {}}
    return http.get(`lawyers?${params}`)
  }

  async getAvailableJurisdictions(){
    return http.get('jurisdictions')
  }

  async createLawyer(data: any) {
    return http.post('/', data)
  }

  async deleteLawyer(email: string) {
    return http.delete(`/?email=${email}`)
  }

  async createStandbyLawyer(data: any) {
    return http.post('standby', data)
  }

  async deleteStandbyLawyer(email: string) {
    return http.delete(`standby?email=${email}`)
  }

  async canLogin(data: any) {
    return http.post('login', data)
  }

  async lawyerExists(data: any) {
    return http.post('verifyLawyer', data)
  }

  async getSizeOfStandbyData() {
    return http.get('standby')
  }

  async fetchStandByLawyers(data: any) {
    return http.post('standbyPage', data)
  }
}

export default new LawyerDataService()