import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from '../../app/store';

type TableEntry = {
  jurisdiction?: string,
  name?: string,
  bio?: string,
  expertiseAreas?: string[],
  email?: string,
  linkedIn?: string,
  twitter?: string
  facePhoto?: string,
  expander?: any,
  walletAddress?: string,
  experience?: string
}

export interface ApplicationFormState {
  open: boolean;
  data: TableEntry;
}

const initialState: ApplicationFormState = {
  open: false,
  data: {}
};

export const applicationFormSlice = createSlice({
  name: 'applicationForm',
  initialState,
  reducers: {
    toggle: (state, action: PayloadAction<TableEntry>) => {
      state.open = !state.open;
      state.data = action.payload;
    },
  },
});

export const { toggle } = applicationFormSlice.actions;

export const selectOpen = (state: RootState) => state.applicationForm.open;
export const selectData = (state: RootState) => state.applicationForm.data;

export default applicationFormSlice.reducer;
