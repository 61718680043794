import { Button, Col, Container, Row } from "react-bootstrap";
import { useEffect, useState } from "react";

import LawyerDataService from "../../services/lawyer";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectLogin } from "../../features/login/loginSlice";
import { Navbar } from "../Navbar/Navbar";

import 'react-toastify/dist/ReactToastify.css';
import "./AcceptUsers.css";
import { setLoading } from "../../features/Loader/loaderSlice";

type IData = {
  jurisdiction: string,
  name: string,
  bio: string,
  expertiseAreas: string[],
  email: string,
  twitter: string,
  linkedIn: string,
  facePhoto: string,
  walletAddress: string,
  experience: string
}

export const AcceptUsers = () => {
  const dispatch = useAppDispatch();
  const show = useAppSelector(selectLogin);
  const [dataToShow, setDataToShow] = useState<IData[]>([]);

  const [pageNumbers, setPageNumbers] = useState<number[]>([])
  const pageSize = 10

  useEffect(() => {
    const getStandby = async (start: number, end: number) => {
      dispatch(setLoading())
      const data = {
        start: start,
        end: end
      }
      const standBy = await LawyerDataService.fetchStandByLawyers(data);
      setDataToShow(standBy.data);
      dispatch(setLoading())
    }

    if(show) {
      const getSizeOfDataFunc = async () => {
        const getSizeOfDataRes = await LawyerDataService.getSizeOfStandbyData()

        setPageNumbers(Array.from({length: Math.ceil(getSizeOfDataRes.data / pageSize)}, (_, index) => index + 1))
      }
      
      getSizeOfDataFunc()

      getStandby(0, pageSize)
    }
  }, [show, dispatch])

  const acceptUser = async (user: any) => {
    if(window.confirm('Are you sure you want to add this user to the database?')){
      dispatch(setLoading())
      // Add lawyer
      await LawyerDataService.createLawyer(user)
      // Remove lawyer from standby
      await LawyerDataService.deleteStandbyLawyer(user.email)
      dispatch(setLoading())
    }
  }

  const declineUser = async (email: string) => {
    if(window.confirm('Are you sure you want to remove this user from the database?')){
      dispatch(setLoading())
      await LawyerDataService.deleteStandbyLawyer(email)
      dispatch(setLoading())
    }
  }

  const getStandby = async (start: number, end: number) => {
    dispatch(setLoading())
    const data = {
      start: start,
      end: end
    }
    const standBy = await LawyerDataService.fetchStandByLawyers(data);
    setDataToShow(standBy.data);
    dispatch(setLoading())
  }

  const handleClick = async (el: number) => {
    await getStandby(el * pageSize - pageSize, el * pageSize)
  }

  return (
    <>
      <Navbar />
      <Container>
        {show && <h2 className="au-title"><b>Members to accept</b></h2>}
        {
          show && 
          <div style={{ marginBottom: '30px'}}>
            {pageNumbers.map(el => <Button onClick={async () => await handleClick(el)}>{el}</Button>)}
          </div>
          
        }
        {show && dataToShow.length ? dataToShow.map((user: IData) => {
          return (
            <Row className="au-row">
              <Col>
                <p><b>Jurisdiction:</b> {user.jurisdiction}</p>
                <p><b>Name:</b> {user.name}</p>
                <p><b>Bio:</b> {user.bio}</p>
                <p><b>Areas of Expertise:</b></p>
                {user.expertiseAreas.map((e: string) => <p>{e}</p>)}
                <p><b>Email:</b> {user.email}</p>
                <p><b>Twitter:</b> {user.twitter}</p>
                <p><b>LinkedIn:</b> {user.linkedIn}</p>
                <p><b>Wallet Address:</b> {user.walletAddress}</p>
                <p><b>Experience:</b> {user.experience}</p>
              </Col>
              <Col>
                <p><b>Photo:</b></p>
                <img className="au-img" src={user.facePhoto} alt={user.name}/>
              </Col>
              <Col>
                <Button className="au-button" onClick={() => acceptUser(user)}>Accept</Button>
                <Button className="au-button" onClick={() => declineUser(user.email)}>Decline</Button>
              </Col>
            </Row>
          )
        }) : null}
        {show && !dataToShow.length ? 
          <Row>
            <Col>
              <h1>Empty</h1>
            </Col>
          </Row>
        : null }
      </Container>
    </>
  );
}